.root {
    margin:3rem;
}

.layoutContainer
{
    height:80vh;
    display:flex;
    align-items: center;
    justify-content: center;
}


@media (max-width: 767px) {
    .layoutContainer {
        margin: 3rem 10%;
    }
}

@media (min-width:768px) and (max-width: 991px) {
    .layoutContainer {
        margin: 3rem 20%;
    }
}

@media (min-width:992px) and (max-width: 1199px) {
    .layoutContainer {
        margin: 3rem 25%;
    }
}


@media (min-width: 1200px) {
    .layoutContainer {
        margin: 2rem 30%;
    }
}
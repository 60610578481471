* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: "couture";
    src: url('./font/couture-bld.otf')
}

@font-face {
    font-family: "cocogoose";
    src: url('./font/Cocogoose\ Pro-trial.ttf');
}

@font-face {
    font-family: "OpenSans-Regular";
    src: url("./font/OpenSans-Regular.ttf");
}


.logo-font {
    font-family: couture, sans-serif;
}

.navbar-font {
    font-family: OpenSans-Regular, sans-serif;
}

.nav {
    /* Add a transition for a smooth effect */
    transition: all 0.3s ease;

    /* Existing rules */
    position: sticky;
    top: -5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
    background-color: white;

    z-index: 2;

}

.nav.sticky .toggle-button {
    top: 50%;
    transform: translateY(-50%);
}

.nav.sticky {
    /* Change the background color and add box-shadow for a better UX */
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    /* Increase the height when navbar is sticky */
    height: 70px;
}


.nav.sticky .site-title {
    font-size: 2rem;
    margin: 0.6rem 2rem;
}

.nav.sticky .nav-links {
    margin-right: 3rem;
    margin-top: 0rem;
    transform: translateY(-7px);
}

.nav.sticky .nav-links .dropdown-menu {
    top: calc(80%);
    /* Adjust the value as needed */
}

.nav.sticky .dropdown-menu {
    top: 100%;
}



.site-title {
    font-size: 3rem;
    color: inherit;
}

.nav ul {
    margin: 0;
    padding: 0;
    display: flex;
}

.nav li {
    list-style: none;
}

.nav .site-title,
.nav-link {
    text-decoration: none;
}

.nav .nav-link {
    z-index: 1;
    height: 2rem;
    padding: 0.4rem;
    display: block;
    color: black;
    position: relative;
    transition: transform 0.3s ease;
}


.nav .nav-link.active {
    transform: translateY(50px);
}

.nav-link::after {
    content: '';
    height: 2.8px;
    width: 85%;
    background: black;
    position: absolute;
    left: 4px;
    bottom: -0.5px;
    opacity: 0;
    transition: all 0.4s;
    pointer-events: none;
}

.nav-link:hover::after,
.isCurPath::after {
    opacity: 1;
}

.toggle-button {
    position: absolute;
    top: .8rem;
    right: 1.0rem;
    display: none;
    margin-right: 0.75rem;
    flex-direction: column;
    justify-content: space-around;
    width: 25px;
    height: 20px;
}

.toggle-button .bar {
    height: 1px;
    width: 100%;
    background-color: rgb(0, 0, 0);
    border-radius: 10px;
}

@media (max-width: 759px) {
    .nav.sticky {
        background-color: rgba(255, 255, 255, 1);
    }

    .toggle-button {
        display: flex;
    }

    .nav {
        height: 4rem;
    }

    .nav {
        flex-direction: column;
        align-items: flex-start;
    }

    .nav-links {
        display: flex;
        width: 100%;
        flex-direction: column;
        text-align: center;
        padding: 0;
    }

    .nav-link:hover {
        background-color: gray;
    }

    .nav-link {
        background-color: white;
        padding: .4rem .3rem;
        margin: -3px 0 2px 0;
    }

    .nav-link.active {
        display: flex;
    }

    .nav .site-title {
        margin: .5rem 1.5rem;
        font-size: 2.1rem;
    }

    .nav-link::after {
        display: none;
    }

    .dropdown-menu {
        display: flex;
        flex-direction: column;
        position: static;
        width: 100%;
        overflow: visible;
    }

    .nav-links .dropdown:hover .dropdown-menu {
        /* Remove hover effect on smaller screens */
        max-height: none;
    }

    .nav-links .dropdown .dropdown-menu li {
        /* Adjust the display of the dropdown items on smaller screens */
        display: block;
        opacity: 1;
        width: 100%;

    }

    .nav.sticky .site-title {
        margin-top: 0.8rem;
        /* Adjust this value as needed */
    }

    .nav {
        transition: none;
    }
}

@media (max-width:425px) {
    .nav .site-title {
        margin: 0.2rem 1.5rem;
    }
}

@media (min-width:760px) and (max-width: 991px) {
    .nav {
        height: 6rem;
    }

    .nav .nav-links {
        margin-right: 3rem;
    }

    .nav .site-title {
        margin: .2rem 3rem;
        font-size: 2.4rem;
    }
}

@media (min-width:992px) and (max-width: 1199px) {
    .nav {
        height: 8rem;
    }

    .nav .nav-links {
        margin-right: 4rem;
    }

    .nav .site-title {
        margin: .3rem 4rem;
        font-size: 2.6rem;
    }
}

@media (min-width: 1200px) {
    .nav {
        height: 10rem;
    }

    .nav .nav-links {
        margin-right: 6rem;
    }

    .nav .site-title {
        margin: .4rem 6rem;
    }
}


.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-menu {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    z-index: 1;
    top: 90%;
    left: -140%;
    width: 500%;
    text-align: center;
    max-height: 0;
    /* Add max-height */
    overflow: hidden;
    /* Add overflow */
    transition: max-height 0.5s ease;
    /* Add transition for a smooth effect */
}

.nav-links .dropdown:hover .dropdown-menu {
    max-height: 300px;
    /* Adjust this value based on your dropdown menu's height */
}

.nav.sticky .dropdown-menu {
    top: 70%;
    /* Adjust this value based on the desired height of the sticky navbar */
}



.dropdown-menu li {
    margin: 0.3rem 0.2rem 0 0;
    display: none;
    position: relative;
    width: 45%;
    vertical-align: top;
    font-size: 0.8rem;
    color: #000;
    /* align the li items to the top of the container */
    user-select: none;
}

.nav-links .dropdown:hover .dropdown-menu li {
    display: inline-block;
    opacity: 1;
}


.dropdown-menu li span {
    display: inline-block;
    width: 100%;
    padding: 4px 2px 0 0;
    color: #000;
    text-decoration: none;
}

.dropdown-menu li:nth-of-type(2n) span {
    padding-top: 3px;
}


.dropdown-menu li:first-of-type {
    width: 25%;
}

.dropdown-menu li:last-of-type {
    width: 32%;
}

.dropdown-menu li.separator {
    width: 2%;
    padding: 0;
}

.dropdown-menu li[draggable="false"] {
    -webkit-user-drag: none;
    /* Disable dragging in Safari */
    -webkit-touch-callout: none;
    /* Disable callouts in Safari */
    -moz-user-select: none;
    /* Disable selection in Firefox */
    -ms-user-select: none;
    /* Disable selection in IE */
    user-select: none;
    /* Disable selection in other browsers */
}

.tag-selector {
    display: none;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.tag-selector button {
    background: none;
    border: none;
    color: #333;
    text-transform: uppercase;
    cursor: pointer;
    padding: 0;
    transition: color 0.2s;
    font-family: OpenSans-Regular, sans-serif;
}

.tag-selector button.active{
    font-weight: bold;
}


@media (max-width: 760px) {
    .tag-selector {
        padding-left: 2rem;
        display: flex;
        justify-content: flex-start;
        gap: 0.5rem;
    }
}

@media (max-width: 425px) {
    .tag-selector {
        padding-left: 0.7rem;
        display: flex;
        justify-content: flex-start;
        gap: 0.4rem;
        font-size: 0.7rem;
    }
}
@font-face {
    font-family: "OpenSans-Regular";
    src: url("../font/OpenSans-Regular.ttf");
}

.result-message {
    font-family: OpenSans-Regular, sans-serif;
    /* font-size:1.5rem; */
    margin: 0 0 15% 0
}

.message {
    margin-bottom: 1.5rem;;
}
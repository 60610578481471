@font-face {
  font-family: "OpenSans-Regular";
  src: url("../font/OpenSans-Regular.ttf");
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0;
  margin: 2vh auto 0;
}

.video-iframe {
  width: 100%;
}

.video-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: OpenSans-Regular, sans-serif;
  font-size: 1.15rem;
}

.video-details {
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  font-weight:bold;
  font-weight: 1.15rem;
  text-align: left;
  flex:4;
}

.video-switcher {
  display: flex;
  justify-content: flex-end;
  flex:1;
}

.video-switcher:nth-child(2n-1):hover {
  cursor: pointer;
}

.video-switcher a {
  color: #000;
  font-weight: bold;
  text-decoration: none;
  margin: 0 2px;
}

.video-switcher-separator {
  margin: 0 6px;
  color: #000;
  font-weight: bold;
  pointer-events: none;
}

/* Mobile (small) */
@media (max-width: 576px) {
  .video-text {
    font-size: 14px;
  }
}

/* Mobile (medium) */
@media (min-width: 577px) and (max-width: 768px) {
  .video-text {
    font-size: 2.5vw;
  }
}

/* Tablet */
@media (min-width: 769px) and (max-width: 992px) {
  .video-text {
    font-size: 1.7vw;
  }
}

/* Desktop (small) */
@media (min-width: 993px) and (max-width: 1200px) {
  .video-text {
    font-size: 1.3vw;
  }
}

/* Desktop (large) */
@media (min-width: 1201px) {
  .video-text {
    font-size: 1vw;
  }
}

.thumbnail-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  transition: opacity 0.5s ease-in-out;
}

.thumbnail-image {
  object-fit: cover;
}

.play-button {
  position: absolute;
  width: 10%; /* Adjust the size of the play button as needed */
  height: auto;
}



.main-container {
    /* Default padding for mobile view */
    margin-left: 1rem;
    margin-right:1rem;
    /* background-color: aqua; */
}

/* For screens 760px and above */
@media (min-width: 760px) {
    .main-container {
        margin-left: 3rem;
        margin-right: 3rem;
    }
}

/* For screens 992px and above */
@media (min-width: 992px) {
    .main-container {
        margin-left: 4rem;
        margin-right: 4rem;
    }
}

/* For screens 1200px and above */
@media (min-width: 1200px) {
    .main-container {
        margin-left: 6rem;
        margin-right: 6rem;
    }
}

@font-face {
    font-family: "OpenSans-Regular";
    src: url("../font/OpenSans-Regular.ttf");
}

.input {
    background-attachment: local;
    background-image:
        repeating-linear-gradient(white, white 43.5px, black 44px, white 45px);
    line-height: 45px;
    border: 0;
    padding: 8px 10px;
    resize: none;
    width: 24rem;
    font-family: OpenSans-Regular, sans-serif;
    font-size:1.1rem;
}

.input:focus {
    outline: none;
}

.contact-label {
    font-family: OpenSans-Light, sans-serif;
    /* font-size:1.3rem; */
}


.field {
    margin: 5% 0;
    font-weight: 700;
}

.field:last-of-type {
    margin: 2% 0;
}

.submit-btn {
    background-image: url('../../public/images/submit-button.png');
    background-color: white;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    width: 33px;
    height: 33px;
}

.submit-btn:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    transition: 1s ease;
    cursor: pointer;
}

@media (max-width:508px) {
    .input {
        width: 100%;
    }
}

.contact-container {
    /* padding-left:9.5vw; */
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: #555;
  }
  
  .loading::after {
    content: "";
    display: block;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    border: 3px solid #000000;
    border-color: #000000 transparent #000000 transparent;
    animation: loading 2s linear infinite;
  }
  
  @keyframes loading {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
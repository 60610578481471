.login-container {
    font-family: helvetica;
    color: #333;
    padding: 0 15px;
  }
  
  .login-container * {
    box-sizing: border-box;
  }
  
  .login-heading {
    font-size: 36px;
    line-height: 1.5;
    font-weight: bold;
    margin: 10px 0;
    text-align: center;
  }
  
  
  
  .login-form {
    margin: 60px auto;
    display: block;
    padding: 20px 30px 30px 30px;
    max-width: 480px;
    background: #ededed;
    border-radius: 8px;
    box-shadow: 0 2px 0 1px rgba(0, 0, 0, 0.1);
  }
  
  .input-field {
    position: relative;
    display: block;
    margin: 10px 0;
    height: 54px;
    width: 100%;
    background: white;
    border: 1px solid #f0f0f0;
    border-radius: 3px;
    padding: 0 10px;
    font-size: 16px;
    color: #333;
    border-radius: 3px;
    border: 1px solid transparent;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  
  .input-field:focus {
    outline: none;
    border-color: #ededed;
  }
  
  .login-button {
    display: block;
    margin: 30px 0;
    height: 54px;
    width: 100%;
    background: #33cc77;
    border: 1px solid #29b765;
    border-top-color: transparent;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    text-shadow: -1px -1px rgba(0, 0, 0, 0.1);
    transition: background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  
  .login-button:hover {
    background: #2db367;
    cursor: pointer;
  }
  
.admin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.admin-panel-title {
  margin-bottom: 1rem;
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1rem;
  margin-top: 1rem;
}

.video-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.video-card {
  background-color: #f1f1f1;
  border-radius: 5px;
  width: 250px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem 1rem 0;
  justify-content: space-between;
  height: 100%;
}

.video-id {
  font-size: 0.8rem;
  color: #555;
  margin-bottom: 0.5rem;
}

.video-tag {
  font-size: 0.9rem;
  color: #444;
  margin-bottom: 0.5rem;
}

.video-card h3 {
  max-height: 4.5em;
  overflow: hidden;
  text-align: center;
}

.video-card p {
  margin: 10px;
}

.video-thumbnail {
  width: 100%;
  height: 0;
  height: 140px;
  padding-bottom: 56.25%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 1rem;
}

.admin-buttons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: auto;
}

.card {
  width: 300px;
  height: 350px;
  overflow: hidden;
  border: #444;
  margin: 10px;
}

.card h3 {
  max-height: 4.5em;
  overflow: hidden;
  text-align: center;
}

.video-thumbnail {
  width: 100%;
  height: 0;
  height: 140px;
  padding-bottom: 56.25%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 1rem;
}


.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  /* Reduced margin for more compact styling */
}

label {
  font-weight: bold;
  margin-bottom: 0.25rem;
  /* Reduced margin for more compact styling */
}

input {
  padding: 0.5rem;
  border: none;
  border-radius: 3px;
  background-color: #f9f9f9;
  margin-bottom: 0.5rem;
  /* Reduced margin for more compact styling */
}

button[type="submit"] {
  padding: 0.5rem 1rem;
  border: none;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  margin-top: 0.5rem;
  /* Reduced margin for more compact styling */
}

button[type="submit"]:hover {
  background-color: #45a049;
}

.small-image-preview {
  max-width: 250px;
  max-height: 300px;
  object-fit: contain;
}

.order-button-container {
  display: flex;
}

/* Updated button styles */
.order-button,
.logout-button,
.edit-about-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 3px;
  font-weight: bold;
  cursor: pointer;
}

.edit-about-button {
  margin-top: 30px;
  background-color: #6462c5;
  color: white;
}



.order-button {
  background-color: #4CAF50;
  color: white;
  width: 100px;
  margin: 30px;
}

.order-button:hover {
  background-color: #45a049;
}

.logout-button {
  background-color: #f44336;
  color: white;
}

.logout-button:hover {
  background-color: #da190b;
}

.add-video {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.delete-button {
  background-color: rgb(255, 148, 148);
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 3px;
  font-weight: bold;
  cursor: pointer;
}

.edit-button {
  background-color: rgb(110, 99, 223);
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 3px;
  font-weight: bold;
  cursor: pointer;
}
@font-face {
  font-family: OpenSans-Regular;
  src: url(../font/OpenSans-Regular.ttf);
}

.works-container .infiniteScroll {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
  gap: 0.5rem 0.5rem;
  min-height: 100vh;
  margin: 0 auto;
}

.video-container {
  position: relative;
  margin: 0 0;
  flex: 1 1 100%;
}

.video {
  object-fit: cover;
  width: 100%;
  height: auto;
  transition: transform 0.4s ease-in-out, filter 0.4s ease-in-out;
}

.overlay {
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 98.0%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
}

.video-container:hover .overlay {
  opacity: 1;
  transform: scale(1.01);
}

.content {
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.content p {
  font-family: OpenSans-Regular, sans-serif;
  background-color: transparent;
  margin: 0;
}

.video-container:hover .video {
  transform: scale(1.01);
  filter: blur(3px);
}

.video-text {
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  text-align: center;
}

/* For screen width 650px or less */
@media (max-width: 650px) {
  .video-container {
    flex: 1 1 100%;
  }
}

/* For screen width between 765px and 1400px */
@media (min-width: 765px) and (max-width: 1400px) {
  .video-container {
    flex: 1 1 calc(50% - 1rem);
    /* max-width:calc(50% - 1rem); */
  }

  .video-container.one-video {
    max-width: calc(50% - 1rem);
  }
}

/* For screen width between 1401px and 2100px */
@media (min-width: 1401px) and (max-width: 2100px) {
  .video-container {
    flex: 1 1 calc(33.33% - 1rem);
    /* max-width:calc(33.33% - 1rem); */
  }

  .video-container.one-video {
    max-width: calc(50% - 1rem);
  }
}

/* For screen width greater than 2100px */
@media (min-width: 2101px) {
  .video-container {
    flex: 1 1 calc(25% - 1rem);
    /* max-width:calc(25% - 1rem); */
  }

  .video-container.one-video {
    max-width: calc(50% - 1rem);
  }
}



.scroll-to-top-btn {
  background-image: url("/public/images/icon/scroll_to_top_icon.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 200%;
  display: none;
  border-radius: 50%;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  opacity: 0.3;
  transition: 0.5s;
}

.scroll-to-top-btn:hover {
  opacity: 1;
  transform: scale(1.1);
}

@font-face {
    font-family: "OpenSans-Regular";
    src: url("../font/OpenSans-Regular.ttf");
}

.about-content {
    margin: 0 0 15% 0;
    font-family: OpenSans-Regular, sans-serif;
}


